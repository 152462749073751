import React, { useMemo, useCallback, useState } from 'react';
import { useDataStats } from '../../hooks/useDataStats';
import { useSortableData } from '../../hooks/useSortableData';
import { shouldShowPercentChange } from '../../utils/chartUtils';
import { useTheme } from '../../auth/AuthContext';
import { graphColors } from '../../Utilities';
import { LineChartTemplate, MetricCard } from '../Layouts/ChartTemplates';
import LoadingSpinner from '../Layouts/LoadingSpinner';
import { metricsConfig, transformGBPData } from '../../config/GBPConfig';

const GBP = ({
    data,
    yearOverYearData,
    previousPeriodData,
    customPeriodData,
    loadingState,
    allLocations,
    locationData = [],
    pdfRefs,
    isPDFLayout
}) => {
    // Only initialize selectedMetrics if we're not showing locations
    const [selectedMetrics, setSelectedMetrics] = useState(['TOTAL_GBP_INTERACTIONS']);
    
    const theme = useTheme();
    const lineColors = graphColors(theme);

    const stats = useDataStats(
        data,
        yearOverYearData,
        previousPeriodData,
        customPeriodData,
        metricsConfig
    );

    const locationStatsArray = locationData.map(location => ({
        name: location.name,
        stats: useDataStats(
            location.data || {},
            location.yearOverYearData || null,
            location.previousPeriodData || null,
            location.customPeriodData || null,
            metricsConfig
        )
    }));

    const transformedData = useMemo(() =>
        transformGBPData(data, yearOverYearData, previousPeriodData, customPeriodData),
        [data, yearOverYearData, previousPeriodData, customPeriodData]
    );

    const { sortedData: sortedChartData } = useSortableData(transformedData.chartData || [], {
        key: 'date',
        direction: 'asc'
    });

    const getRef = () => {
        if (isPDFLayout) {
            if (!pdfRefs || Object.keys(pdfRefs).length === 0) return null;
            const key = Object.keys(pdfRefs)[0];
            const ref = pdfRefs[key] && pdfRefs[key].ref;
            return ref ? el => { ref.current = el; } : null;
        }
        return pdfRefs && pdfRefs.overview && pdfRefs.overview.ref || null;
    };

    if (loadingState) {
        return (
            <div className='flex justify-center items-center h-64'>
                <LoadingSpinner />
            </div>
        );
    }

    if (!data?.[0]?.dailyMetricTimeSeries) {
        return (
            <div className='flex justify-center items-center h-64'>
                <p className='text-gray-500 text-lg'>No data available for the selected date range.</p>
            </div>
        );
    }

    // Only handle metric clicks if we're not showing locations
    const handleMetricClick = useCallback((metricKey) => {
        if (allLocations) return;
        
        setSelectedMetrics(prev => {
            const newSelected = prev.includes(metricKey)
                ? prev.filter(m => m !== metricKey)
                : [...prev, metricKey];
            
            return newSelected.length === 0 ? [metricKey] : newSelected;
        });
    }, [allLocations]);

    // Line chart configuration based on whether we're showing locations
    const lineChartConfig = useMemo(() => {
        if (allLocations) {
            // Return original fixed configuration for locations view
            return {
                data: sortedChartData,
                lines: [
                    {
                        dataKey: 'WEBSITE_CLICKS',
                        name: 'Website Clicks',
                        color: lineColors.first
                    },
                    {
                        dataKey: 'BUSINESS_DIRECTION_REQUESTS',
                        name: 'Direction Requests',
                        color: lineColors.second
                    },
                    {
                        dataKey: 'CALL_CLICKS',
                        name: 'Call Clicks',
                        color: lineColors.third
                    }
                ],
                xAxisDataKey: 'date',
                height: 500,
                showLegend: true
            };
        }

        // Return dynamic configuration for multi-select view
        const colorKeys = ['first', 'second', 'third', 'fourth', 'fifth', 'sixth'];
        return {
            data: sortedChartData,
            lines: selectedMetrics.map((metric, index) => ({
                dataKey: metric,
                name: metricsConfig[metric].label,
                color: lineColors[colorKeys[index % colorKeys.length]],
                strokeWidth: 2,
                dot: { r: 2 }
            })),
            xAxisDataKey: 'date',
            height: 500,
            showLegend: true
        };
    }, [selectedMetrics, sortedChartData, lineColors, allLocations]);

    return (
        <div id='dashboard-gbp'>
            <div
                className='pdf-page viewport'
                ref={getRef()}
                data-pdf-title='Google Business Profile'
                data-pdf-scale='0.98'
            >
                <div className='chart-metrics-wrapper'>
                    <div className='chart-metrics-metrics'>
                        <div className='chart-metrics-grid'>
                            {Object.entries(stats).map(([key, stat]) => (
                                <div 
                                    key={key}
                                    onClick={() => handleMetricClick(key)}
                                    className='metric-card-select-wrapper'
                                >
                                    <MetricCard
                                        label={metricsConfig[key].label}
                                        value={stat.value}
                                        yearOverYearChange={stat?.yearOverYearChange ?? null}
                                        previousPeriodChange={stat?.previousPeriodChange ?? null}
                                        customPeriodChange={stat?.customPeriodChange ?? null}
                                        isSelected={!allLocations && selectedMetrics.includes(key)}
                                        multiSelect={!allLocations}
                                        cardClass={key === 'TOTAL_GBP_INTERACTIONS' ? 'gbb-metrics-total' : undefined}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>

                    {allLocations && locationData.length > 0 ? (
                        <div className='gbp-all-locations-table'>
                            <div className='gbp-table-wrapper'>
                                <table className='gbp-table'>
                                    <thead>
                                        <tr>
                                            <th className='thead-title'>Location</th>
                                            {Object.entries(metricsConfig).map(([key, { label }]) => (
                                                <th key={key} className='thead-location w-96'>{label}</th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {locationStatsArray.map(({ name, stats }) => (
                                            <tr key={name} className='gbp-tr'>
                                                <td className='gbp-td-location'>{name}</td>
                                                {Object.entries(stats).map(([key, stat]) => (
                                                    <td key={key} className='gbp-td-value'>
                                                        <div className='flex flex-col'>
                                                            <span>{stat.value.toLocaleString()}</span>
                                                            {stat.yearOverYearChange !== null && shouldShowPercentChange(stat.yearOverYearChange) && (
                                                                <span className={`text-xs ${stat.yearOverYearChange >= 0 ? 'text-green-600' : 'text-red-600'}`}>
                                                                    {stat.yearOverYearChange > 0 ? '+' : ''}{stat.yearOverYearChange.toFixed(1)}% YOY
                                                                </span>
                                                            )}
                                                            {stat.previousPeriodChange !== null && shouldShowPercentChange(stat.previousPeriodChange) && (
                                                                <span className={`text-xs ${stat.previousPeriodChange >= 0 ? 'text-green-600' : 'text-red-600'}`}>
                                                                    {stat.previousPeriodChange > 0 ? '+' : ''}{stat.previousPeriodChange.toFixed(1)}% Previous
                                                                </span>
                                                            )}
                                                            {stat.customPeriodChange !== null && shouldShowPercentChange(stat.customPeriodChange) && (
                                                                <span className={`text-xs ${stat.customPeriodChange >= 0 ? 'text-green-600' : 'text-red-600'}`}>
                                                                    {stat.customPeriodChange > 0 ? '+' : ''}{stat.customPeriodChange.toFixed(1)}% Custom
                                                                </span>
                                                            )}
                                                        </div>
                                                    </td>
                                                ))}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    ) : (
                        <div className='chart-metrics-chart'>
                            <LineChartTemplate {...lineChartConfig} />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default React.memo(GBP);
