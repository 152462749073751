export const metricsConfig = {
    totalReviews: {
        calculate: (data) => {
            try {
                return data?.totalReviewCount || 0;
            } catch (error) {
                console.error('Error calculating total reviews:', error);
                return 0;
            }
        },
        label: 'Total Reviews'
    },
    averageRating: {
        calculate: (data) => {
            try {
                return parseFloat(data?.averageRating) || 0;
            } catch (error) {
                console.error('Error calculating average rating:', error);
                return 0;
            }
        },
        label: 'Average Rating',
        isStarRating: true
    },
    recentReviews: {
        calculate: (data) => {
            try {
                if (!data?.reviews) return 0;
                const thirtyDaysAgo = new Date();
                thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 60);
                
                return data.reviews.filter(review => {
                    const reviewDate = new Date(review.createTime);
                    return reviewDate >= thirtyDaysAgo;
                }).length;
            } catch (error) {
                console.error('Error calculating recent reviews:', error);
                return 0;
            }
        },
        label: 'Reviews - Last 60 Days'
    },
    replyPercentage: {
        calculate: (data) => {
            try {
                if (!data?.reviews || data.reviews.length === 0) return 0;
                
                const reviewsWithReplies = data.reviews.filter(review => 
                    review.reviewReply && review.reviewReply.comment
                ).length;
                
                return (reviewsWithReplies / data.reviews.length) * 100;
            } catch (error) {
                console.error('Error calculating reply percentage:', error);
                return 0;
            }
        },
        label: 'Review Reply Rate'
    }
};
