export const metricsConfig = {
    TOTAL_GBP_INTERACTIONS: {
        calculate: (data) => {
            return calculateMetricTotal(data, 'WEBSITE_CLICKS') +
                   calculateMetricTotal(data, 'BUSINESS_DIRECTION_REQUESTS') +
                   calculateMetricTotal(data, 'CALL_CLICKS');
        },
        label: 'Total GBP Interactions'
    },
    IMPRESSIONS_SEARCH: {
        calculate: (data) => calculateMetricTotal(data, 'BUSINESS_IMPRESSIONS_DESKTOP_SEARCH') + 
                           calculateMetricTotal(data, 'BUSINESS_IMPRESSIONS_MOBILE_SEARCH'),
        label: 'Impressions (Search)'
    },
    IMPRESSIONS_MAPS: {
        calculate: (data) => calculateMetricTotal(data, 'BUSINESS_IMPRESSIONS_DESKTOP_MAPS') + 
                           calculateMetricTotal(data, 'BUSINESS_IMPRESSIONS_MOBILE_MAPS'),
        label: 'Impressions (Maps)'
    },
    WEBSITE_CLICKS: {
        calculate: (data) => { return calculateMetricTotal(data, 'WEBSITE_CLICKS'); },
        label: 'Website Clicks'
    },
    BUSINESS_DIRECTION_REQUESTS: {
        calculate: (data) => calculateMetricTotal(data, 'BUSINESS_DIRECTION_REQUESTS'),
        label: 'Direction Requests'
    },
    CALL_CLICKS: {
        calculate: (data) => calculateMetricTotal(data, 'CALL_CLICKS'),
        label: 'Call Clicks'
    }
};

const calculateMetricTotal = (data, metricName) => {    
    if (!data || !Array.isArray(data) || !data[0]?.dailyMetricTimeSeries) {
        return 0;
    }

    const metric = data[0].dailyMetricTimeSeries.find(m => m.dailyMetric === metricName);
    if (!metric?.timeSeries?.datedValues) {
        return 0;
    }

    return metric.timeSeries.datedValues.reduce((total, item) => 
        total + parseInt(item.value || 0, 10), 0
    );
};

const aggregateDataset = (dataset, aggregationType = 'day') => {
    if (!dataset || !dataset[0]?.dailyMetricTimeSeries) {
        console.warn('Invalid dataset structure in aggregateDataset', dataset);
        return [];
    }

    const aggregatedData = new Map();
    const metricsData = dataset[0].dailyMetricTimeSeries;

    metricsData.forEach(metric => {
        const metricName = metric.dailyMetric;
        metric.timeSeries.datedValues.forEach(item => {
            const dateString = `${item.date.year}-${String(item.date.month).padStart(2, "0")}-${String(item.date.day).padStart(2, "0")}`;
            const date = new Date(dateString);
            let key;

            switch (aggregationType) {
                case 'week':
                    const weekStart = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate() - date.getUTCDay()));
                    key = weekStart.toISOString().split('T')[0];
                    break;
                case 'month':
                    key = `${date.getUTCFullYear()}-${String(date.getUTCMonth() + 1).padStart(2, '0')}-01`;
                    break;
                default: // day
                    key = dateString;
            }

            if (!aggregatedData.has(key)) {
                aggregatedData.set(key, {
                    date: new Date(key),
                    WEBSITE_CLICKS: 0,
                    BUSINESS_DIRECTION_REQUESTS: 0,
                    CALL_CLICKS: 0,
                    BUSINESS_IMPRESSIONS_DESKTOP_MAPS: 0,
                    BUSINESS_IMPRESSIONS_MOBILE_MAPS: 0,
                    BUSINESS_IMPRESSIONS_DESKTOP_SEARCH: 0,
                    BUSINESS_IMPRESSIONS_MOBILE_SEARCH: 0
                });
            }

            const aggregatedRow = aggregatedData.get(key);
            aggregatedRow[metricName] = (aggregatedRow[metricName] || 0) + parseInt(item.value || 0, 10);
            
            // Calculate combined metrics
            if (metricName.includes('MAPS')) {
                aggregatedRow.IMPRESSIONS_MAPS = (aggregatedRow.BUSINESS_IMPRESSIONS_DESKTOP_MAPS || 0) + 
                                               (aggregatedRow.BUSINESS_IMPRESSIONS_MOBILE_MAPS || 0);
            }
            if (metricName.includes('SEARCH')) {
                aggregatedRow.IMPRESSIONS_SEARCH = (aggregatedRow.BUSINESS_IMPRESSIONS_DESKTOP_SEARCH || 0) + 
                                                 (aggregatedRow.BUSINESS_IMPRESSIONS_MOBILE_SEARCH || 0);
            }
        });
    });

    return Array.from(aggregatedData.values())
        .sort((a, b) => a.date - b.date);
};

export const transformGBPData = (data, yearOverYearData, previousPeriodData) => {
    if (!data?.[0]?.dailyMetricTimeSeries) return {};

    const currentData = transformDailyGBPData(data[0].dailyMetricTimeSeries);
    const yearOverYearGBPData = yearOverYearData?.[0]?.dailyMetricTimeSeries ? 
        transformDailyGBPData(yearOverYearData[0].dailyMetricTimeSeries) : [];
    const previousPeriodGBPData = previousPeriodData?.[0]?.dailyMetricTimeSeries ? 
        transformDailyGBPData(previousPeriodData[0].dailyMetricTimeSeries) : [];

    return {
        chartData: currentData.map((currentItem, index) => ({
            ...currentItem,
            yearOverYear: yearOverYearGBPData[index] || null,
            previousPeriod: previousPeriodGBPData[index] || null
        }))
    };
};

const transformDailyGBPData = (metricsData) => {
    if (!metricsData) return [];
    
    const dailyData = {};
    
    metricsData.forEach(metric => {
        const metricName = metric.dailyMetric;
        
        metric.timeSeries.datedValues.forEach(item => {
            const dateString = `${item.date.year}-${String(item.date.month).padStart(2, "0")}-${String(item.date.day).padStart(2, "0")}`;
            
            if (!dailyData[dateString]) {
                dailyData[dateString] = {
                    date: dateString,
                    WEBSITE_CLICKS: 0,
                    BUSINESS_DIRECTION_REQUESTS: 0,
                    CALL_CLICKS: 0,
                    IMPRESSIONS_SEARCH: 0,
                    IMPRESSIONS_MAPS: 0,
                    TOTAL_GBP_INTERACTIONS: 0
                };
            }
            
            const value = parseInt(item.value || 0, 10);
            
            if (metricName.includes('MAPS')) {
                dailyData[dateString].IMPRESSIONS_MAPS += value;
            } else if (metricName.includes('SEARCH')) {
                dailyData[dateString].IMPRESSIONS_SEARCH += value;
            } else {
                dailyData[dateString][metricName] = value;
            }

            // Calculate TOTAL_GBP_INTERACTIONS for each date
            if (metricName === 'WEBSITE_CLICKS' || 
                metricName === 'BUSINESS_DIRECTION_REQUESTS' || 
                metricName === 'CALL_CLICKS') {
                dailyData[dateString].TOTAL_GBP_INTERACTIONS = 
                    dailyData[dateString].WEBSITE_CLICKS + 
                    dailyData[dateString].BUSINESS_DIRECTION_REQUESTS + 
                    dailyData[dateString].CALL_CLICKS;
            }
        });
    });

    return Object.values(dailyData).sort((a, b) => a.date.localeCompare(b.date));
};
