import React from 'react';
import { StarIcon } from '@heroicons/react/24/solid';
import { formatDate } from '../../utils/dataProcessing';
import { useDataStats } from '../../hooks/useDataStats';
import LoadingSpinner from '../Layouts/LoadingSpinner';
import { MetricCard } from '../Layouts/ChartTemplates';
import { metricsConfig } from '../../config/reviewsConfig';

const Reviews = ({
    data,
    yearOverYearData,
    previousPeriodData,
    loadingState,
    pdfRefs,
    isPDFLayout
}) => {
    if (loadingState) {
        return <LoadingSpinner />;
    }

    const stats = useDataStats(
        data || {},
        null,
        null,
        null,
        metricsConfig
    );

    const { reviews = [] } = data || {};

    if (!Array.isArray(reviews) || reviews.length === 0) {
        return <div>No reviews available.</div>;
    }

    const renderMetricCard = (key, stat) => {
        if (metricsConfig[key].isStarRating) {
            const rating = parseFloat(stat.value);
            const fullStars = Math.floor(rating);
            const partialFill = rating % 1;

            return (
                <div className='metric-card metric-card-lg'>
                    <h3 className='metric-card-label'>{metricsConfig[key].label}</h3>
                    <div className='metric-card-valuechange'>
                        <div className='metric-card-value flex items-center gap-2'>
                            <div className='flex'>
                                {[...Array(5)].map((_, i) => {
                                    if (i < fullStars) {
                                        return <StarIcon key={i} className='h-6 w-6 text-yellow-400' />;
                                    } else if (i === fullStars && partialFill > 0) {
                                        return (
                                            <div key={i} className='relative w-6 h-6'>
                                                <StarIcon className='absolute h-6 w-6 text-gray-300' />
                                                <div
                                                    className='absolute h-6 overflow-hidden'
                                                    style={{ width: `${partialFill * 100}%` }}
                                                >
                                                    <StarIcon className='h-6 w-6 text-yellow-400' />
                                                </div>
                                            </div>
                                        );
                                    }
                                    return <StarIcon key={i} className='h-6 w-6 text-gray-300' />;
                                })}
                            </div>
                            <span className='hidden lg:inline-block'>({rating.toFixed(1)})</span>
                        </div>
                    </div>
                </div>
            );
        } else if (key === 'replyPercentage') {
            return (
                <div className='metric-card metric-card-lg'>
                    <h3 className='metric-card-label'>{metricsConfig[key].label}</h3>
                    <div className='metric-card-valuechange'>
                        <div className='metric-card-value'>
                            {Math.round(stat.value)}%
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <MetricCard
                key={key}
                label={metricsConfig[key].label}
                value={stat.value}
                yearOverYearChange={stat.yearOverYearChange}
                previousPeriodChange={stat.previousPeriodChange}
                cardClass='metric-card-lg'
            />
        );
    };

    return (
        <div className='pb-12'>
            <div className='pdf-page viewport' data-pdf-title='Reviews Overview' data-pdf-scale='0.98' id='dashboard-reviews'>
                <div className='reviews-overview'>
                    <div className='reviews-metrics'>
                        {Object.entries(stats).map(([key, stat]) => (
                            <React.Fragment key={key}>
                                {renderMetricCard(key, stat)}
                            </React.Fragment>
                        ))}
                    </div>

                    <div className='rr-reviews-table-wrapper'>
                        <div className='recent-reviews-header'>
                            <h2 className='recent-reviews-title'>Recent Reviews</h2>
                        </div>

                        <div className='reviews-table'>
                            {reviews.slice(0, 50).map((review, index) => (
                                <div key={`review-${index}`} className='reviews-table-single'>
                                    <div className='flex items-center justify-between mb-2'>
                                        <div>
                                            <p className='font-semibold'>
                                                {review.reviewer?.displayName || 'Anonymous'}
                                            </p>
                                            <p className='text-sm text-gray-500'>
                                                {formatDate(review.createTime)}
                                            </p>
                                        </div>
                                        <div className='flex items-center'>
                                            {[...Array(5)].map((_, starIndex) => (
                                                <StarIcon
                                                    key={`review-${index}-star-${starIndex}`}
                                                    className={`h-5 w-5 ${starIndex < (review.starRating === 'FIVE' ? 5 : parseInt(review.starRating) || 0) ? 'text-yellow-400' : 'text-gray-300'}`}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                    <p className='text-gray-700 mt-2'>{review.comment}</p>
                                    {review.reviewReply && (
                                        <div className='mt-3 pl-4 border-l-2 border-gray-300'>
                                            <p className='font-semibold'>Owner's reply:</p>
                                            <p className='text-gray-600'>{review.reviewReply.comment}</p>
                                            <p className='text-sm text-gray-500'>
                                                {formatDate(review.reviewReply.updateTime)}
                                            </p>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Reviews;
